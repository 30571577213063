/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:0c20dcb0-de83-4550-8398-400348955842",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_a8Pg5ov4E",
    "aws_user_pools_web_client_id": "1jima0dmmmtge2ovqktj8iv3t2",
    "oauth": {
        "domain": "eco-orion-auth.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "ecooriononemtwo://,exp://127.0.0.1:19000,exp://exp.host/@community/with-webbrowser-redirect,http://localhost:3000/member,https://1m2.asia/member,https://ecoorion.com.my/member,https://staging.1m2.asia/member,https://staging.ecoorion.com.my/member",
        "redirectSignOut": "ecooriononemtwo://,exp://127.0.0.1:19000,exp://exp.host/@community/with-webbrowser-redirect,http://localhost:3000/member,https://1m2.asia/member,https://ecoorion.com.my/member,https://staging.1m2.asia/member,https://staging.ecoorion.com.my/member",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://d62xzld7uvhl5nx5sqdxyfxhsy.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-udrym2oqyjeobdvtue73ugrgum",
    "aws_cloud_logic_custom": [
        {
            "name": "EcoOrionRestApi",
            "endpoint": "https://zlaekij0x9.execute-api.ap-southeast-1.amazonaws.com/staging",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "ecoorionbackend-image170800-staging",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
